import React, { useState, useEffect } from "react";
import "./Sponsors.scss";
import axios from "axios";
import { baseUrl } from "../../../utils/functions";

export default function Sponsors(props) {
    const [sponsors, setSponsors] = useState([]);

    useEffect(() => {
        axios
            .get(`site/get_sponsors?id_club=${props.id_club}`)
            .then((res) => setSponsors(res.data))
    }, [props.id_club]);

    return (
        <div className="sponsors-container">
            <div
                className="sponsors-section-title"
                style={{ color: props.config_options.section_title_color }}
            >
                {"Parteneri"}
            </div>
            <div className="sponsors-list">
                {sponsors.map((item, index) => {
                    return (
                        <div className="sponsor-item-box " key={"sponsor" + index}>
                            <a href={item.sponsor_website} target="_blank" rel="noreferrer">
                                <img
                                    src={baseUrl(`uploads/site/sponsors_logos/${props.id_club}/${item.id_site_sponsor}.png`)}
                                    alt=""
                                />
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
