import React from "react";
import "./Button.scss";

const Button = ({ className, disabled = false, children, backgroundColor = "initial", ...otherProps }) => (
  <button
    className={`custom-button ${className}`}
    disabled={disabled}
    {...otherProps}
    style={{
      borderRadius: Number(otherProps.theme) === 2 ? 40 : 0,
      backgroundColor: backgroundColor,
      color: otherProps.color,
      opacity: disabled ? 0.5 : 1,
      ...otherProps.style,
    }}
  >
    {children}
  </button>
);

export default Button;
