import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import axios from "axios";

import { ReactComponent as ArrowRight } from "../../../assets/icons/svg/arrow-right.svg";
import Input from "../../reusable/Input/Input";
import Button from "../../reusable/Button/Button";
import "./PersonalDataForm.scss";
import moment from "moment";
import _ from "lodash";
import 'moment/locale/ro';
import { calculateFee } from "../../../utils/functions";

const PersonalDataForm = (props) => {

    const months = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie", "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"];

    const when_pay = [
        {
            label: "Prima zi a lunii",
            value: "first"
        },
        {
            label: "A 15-a zi a lunii",
            value: "middle"
        },
        {
            label: "Ultima zi a lunii",
            value: "last"
        }
    ]

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [teams, setTeams] = useState([]);
    const [team, setTeam] = useState(null);

    const [players, setPlayers] = useState([]);
    const [child, setChild] = useState(null);

    const [paymentTypes, setPaymentTypes] = useState([]);
    const [paymentType, setPaymentType] = useState(null);

    const [paymentAmount, setPaymentAmount] = useState("");

    const [monthsToPay, setMonthsToPay] = useState([]);
    const [monthToPay, setMonthToPay] = useState(null);

    const [checkbox, setCheckbox] = useState(false);
    const [dayOfPay, setDayOfPay] = useState(null);

    const [message, setMessage] = useState("");

    const [error, setError] = useState("");

    const [styleFocused1, setStyleFocused1] = useState({ 'borderBottom': `1px solid grey` });
    const [styleFocused2, setStyleFocused2] = useState({ 'borderBottom': `1px solid grey` });
    const [styleFocused3, setStyleFocused3] = useState({ 'borderBottom': `1px solid grey` });

    const [fees, setFees] = useState(null);

    useEffect(() => {
        getTeams(); //get teams for select
        setMonthsForSelect();
    }, []);

    const getTeams = () => {
        axios.get(`site/get_teams_for_payments?id_club=${props.id_club}`)
            .then(res => {
                const teams = [...res.data].map(elem => ({ "label": elem.team_name, "value": elem.id_team }))
                setTeams(teams);
            })
            .catch(err => console.log(err));

    }

    const getPlayers = (team) => {
        if (team?.value) {
            axios.get(`site/get_players_payments_site_3?id_club=${props.id_club}&id_team=${team.value}`)
                .then(res => {
                    if (res.data.success == 1) {
                        const players = [...res.data.list].map(elem => ({ ...elem, "label": elem.label.toUpperCase(), }))
                        setPlayers(players);
                        setFees({
                            club_fee: res.data.club_fee,
                            stripe_fee_percent: res.data.stripe_fee_percent,
                            stripe_fee_units: res.data.stripe_fee_units,
                        });
                    } else {
                        setPlayers([]);
                    }
                })
                .catch(err => console.log(err));
        }
    }

    const setMonthsForSelect = () => {
        let previousMonthYear = moment().add(-6, 'M').startOf('month').locale("ro");
        let nextMonthYear = moment().add(6, 'M').startOf('month').locale("ro");

        let monthsSelect = [];

        if (previousMonthYear < nextMonthYear) {
            let date = previousMonthYear;

            while (date < nextMonthYear) {
                monthsSelect.push({
                    label: _.capitalize(date.format("MMMM YYYY")),
                    value: date.format("M-YYYY"),
                    month: date.format("M"),
                    year: date.format("YYYY"),
                });
                date.add(1, 'month');
            }
        }

        setMonthsToPay(monthsSelect);
    }

    const nextStep = (e) => {
        e.preventDefault();
        if (
            name != "" &&
            email != "" &&
            team != null &&
            child != null &&
            paymentType != null &&
            Number(paymentAmount) >= 2 &&
            (checkbox ? dayOfPay != null : true) &&
            (paymentType?.type == "monthly" ? monthToPay != null : true)
        ) {
            props.dataAuth({
                name: name,
                email: email,
                id_team: team,
                id_child: child?.value,
                payment_type: paymentType?.type,
                payment_type_obj: paymentType,
                payment_amount: paymentAmount,
                recurrent_payment: checkbox,
                day_of_pay: dayOfPay?.value ?? "",
                description: message,
                payment_month: monthToPay?.month ?? "",
                payment_year: monthToPay?.year ?? "",
            });
        } else {
            if (Number(paymentAmount) < 2) {
                setError("Suma minimă este de 2 lei.");
            } else {
                setError("Toate câmpurile sunt obligatorii.");
            }
        }
    }

    return (
        <div className="personal-data-form-container" style={{ display: props.toShow ? "block" : "none", color: props.config_options.contact_color }}>
            <div className="personal-data-form-title">
                {"Pas 1 din 2"}
            </div>
            <div className="personal-data-form-content">
                <form>
                    <div className="form-row">
                        <Input
                            style={styleFocused1}
                            name="name"
                            label="Nume și prenume"
                            value={name}
                            handleChange={(e) => {
                                setName(e.target.value);
                                setError("");
                            }}
                            onFocus={() => {
                                setError("");
                                setStyleFocused1({ 'borderBottom': `1px solid ${props.config_options.buttons_color}` });
                            }}
                            onBlur={() => {
                                setStyleFocused1({ 'borderBottom': `1px solid grey` });
                            }}
                            halfWidth
                        />
                        <Input
                            style={styleFocused2}
                            name="email"
                            label="Email"
                            value={email}
                            handleChange={(e) => {
                                setEmail(e.target.value);
                                setError("");
                            }}
                            onFocus={() => {
                                setError("");
                                setStyleFocused2({ 'borderBottom': `1px solid ${props.config_options.buttons_color}` });
                            }}
                            onBlur={() => {
                                setStyleFocused2({ 'borderBottom': `1px solid grey` });
                            }}
                            halfWidth
                        />
                    </div>
                    <div className="form-row custom-form-row">
                        <div className="group half-width">
                            <div className="select">
                                <label className="child-input-label" style={{ paddingLeft: "5px" }}>{"Echipă"}</label>
                                <Select
                                    className="input-select"
                                    name="echipa"
                                    label="Echipă"
                                    placeholder={"Selectează..."}
                                    value={team}
                                    options={teams}
                                    onChange={(item) => {
                                        setError("");
                                        setTeam(item);
                                        getPlayers(item);
                                        setChild(null);
                                        setPaymentTypes([]);
                                        setPaymentType(null);
                                        setPaymentAmount('');
                                        setCheckbox(false);
                                        setMonthToPay(null);
                                        setDayOfPay(null);
                                        setMessage("");
                                    }}
                                    onFocus={() => setError("")}
                                    styles={{ marginBottom: "50px", width: "100%" }}
                                />
                            </div>
                        </div>
                        <div className="group half-width">
                            <div className="select">
                                <label className="child-input-label" style={{ paddingLeft: "5px" }}>{"Copil"}</label>
                                <Select
                                    className="input-select"
                                    name="child"
                                    label="Copil"
                                    placeholder={"Selectează..."}
                                    value={child}
                                    options={players}
                                    onChange={(item) => {
                                        setError("");
                                        setChild(item);
                                        setPaymentTypes(item?.payment_types);
                                        setPaymentType(null);
                                        setPaymentAmount('');
                                        setCheckbox(false);
                                        setMonthToPay(null);
                                        setDayOfPay(null);
                                        setMessage("");
                                    }}
                                    onFocus={() => setError("")}
                                    styles={{ marginBottom: "50px", width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div
                            className="group no-margin-bottom half-width"
                            style={{ display: "flex", gap: "25px" }}
                        >
                            <div className="select">
                                <label className="child-input-label" style={{ paddingLeft: "5px" }}>{"Tip plată"}</label>
                                <Select
                                    className="input-select"
                                    name="payment"
                                    label="Tip plata"
                                    options={paymentTypes}
                                    placeholder={"Selectează..."}
                                    value={paymentType}
                                    onChange={(item) => {
                                        setError("");
                                        setPaymentType(item)
                                        if (item?.type != 'other') {
                                            setPaymentAmount(item.amount);
                                        } else {
                                            setPaymentAmount('');
                                        }
                                        setCheckbox(false);
                                        setMonthToPay(null);
                                        setDayOfPay(null);
                                        setMessage("");
                                    }}
                                    onFocus={() => setError("")}
                                    styles={{ marginBottom: "50px", width: "100%" }}
                                />
                            </div>
                            {paymentType?.value && (
                                <div className="quarter-width align-corner-left">
                                    <label className="child-input-label">{"Sumă"}</label>
                                    <input
                                        className="form-input"
                                        disabled={props.editableMonthlyTax == 0 && paymentType?.value != "other"}
                                        value={paymentAmount}
                                        onChange={(event) => {
                                            setPaymentAmount(event.target.value);
                                        }}
                                        onFocus={() => {
                                            setError("");
                                            setStyleFocused3({ 'borderBottom': `1px solid ${props.config_options.buttons_color}` });
                                        }}
                                        onBlur={() => {
                                            setStyleFocused3({ 'borderBottom': `1px solid grey` });
                                        }}
                                        style={styleFocused3}
                                    />
                                    <label className="child-input-label">{"lei"}</label>
                                </div>
                            )}
                        </div>
                        {paymentType?.value == "monthly" &&
                            <div className="group no-margin-bottom half-width">
                                <div className="select">
                                    <label className="child-input-label" style={{ paddingLeft: "5px" }}>{"Lună plată"}</label>
                                    <Select
                                        className="input-select"
                                        name="payment"
                                        label="Lună plată"
                                        placeholder={"Selectează..."}
                                        value={monthToPay}
                                        options={monthsToPay}
                                        onChange={(item) => {
                                            setMonthToPay(item)
                                            setError("");
                                        }}
                                        onFocus={() => setError("")}
                                        styles={{ marginBottom: "50px", width: "100%" }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    {Number(paymentAmount) >= 2 && paymentType?.value && props.feePassedToCustomer == 1 && fees &&
                        <div className="form-row commision-message">
                            {`Comisonul pentru această plată este de ${calculateFee(paymentAmount, fees.club_fee, fees.stripe_fee_percent, fees.stripe_fee_units)} RON.`}
                        </div>
                    }
                    {(paymentType?.value == "monthly" || paymentType?.value == "other") &&
                        <div className="form-row custom-form-row half-width top-margin">
                            {/* {paymentType?.value == "monthly" && (
                                <div className="group" style={{ marginBottom: "15px" }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkbox}
                                                    onChange={(item) => {
                                                        setCheckbox(item.target.checked);
                                                        setError("");
                                                        if (item.target.checked == false) {
                                                            setDayOfPay(null);
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Plată lunară recurentă"
                                        />
                                    </FormGroup>
                                </div>
                            )} */}
                            {paymentType?.value == "other" && (
                                <Input
                                    label="Descriere"
                                    value={message}
                                    onChange={(event) => {
                                        setMessage(event.target.value);
                                    }}
                                />
                            )}
                        </div>
                    }
                    {checkbox && (
                        <div className="form-row">
                            <div className="group half-width">
                                <div className="select">
                                    <label className="child-input-label" style={{ paddingLeft: "5px" }}>{"Dată plată recurentă"}</label>
                                    <Select
                                        className="input-select"
                                        name="data_plata"
                                        label="Dată plată recurentă"
                                        placeholder={"Selectează..."}
                                        value={dayOfPay}
                                        options={when_pay}
                                        onChange={(item) => {
                                            setDayOfPay(item);
                                            setError("");
                                        }}
                                        onFocus={() => setError("")}
                                        styles={{ marginBottom: "50px", width: "100%" }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {error !== "" && <div className="error-row">{error}</div>}
                    <div className="button-row" style={{ marginTop: "60px" }}>
                        <Button type="submit" onClick={nextStep}
                            theme={props.config_options.id_theme}
                            color={props.config_options.buttons_text_color}
                            backgroundColor={props.config_options.buttons_color}
                            disabled={name == "" ||
                                email == "" ||
                                team == null ||
                                child == null ||
                                paymentType == null ||
                                Number(paymentAmount) < 2 ||
                                (checkbox ? dayOfPay == null : false) ||
                                (paymentType?.type == "monthly" ? monthToPay == null : false) ||
                                (paymentType?.type == "other" ? message == "" : false)
                            }
                        >
                            {"URMĂTORUL PAS"}
                            <ArrowRight
                                fill={props.config_options.buttons_text_color}
                                className={`send-icon rigth`} />
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PersonalDataForm;
