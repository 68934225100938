import React, { useEffect, useState } from "react";
import "./Competitions.scss";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRight } from "../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";

export default function Competitions(props) {
  const history = useHistory();

  const [competitions, setCompetitions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(`site/get_competitions?id_club=${props.id_club}`)
      .then((res) => {
        setCompetitions(res.data);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="competitions-container">
      <div
        className="competitions-title"
        style={{ color: props.config_options.section_title_color }}
      >
        Competiții
      </div>
      {!loaded ? (
        <div className="loader-container">
          <Loader
            type="ThreeDots"
            color={props.config_options.main_color}
            height={80}
            width={80}
          />
        </div>
      ) : (
        <div className="competitions-list">
          {competitions.map((item) => {
            return (
              <div
                className="competition-card"
                key={item.id_competition}
                style={{
                  borderRadius: props.config_options.id_theme == 2 ? 15 : 0,
                }}
              >
                <div
                  className="competition-name notranslate"
                  style={{ color: props.config_options.groups_name_color }}
                >
                  {item.competition_name}
                </div>
                <div
                  className="competition-age notranslate"
                  style={{ color: props.config_options.groups_name_color }}
                >
                  {item.age_restriction}
                </div>
                <div className="competition-more-button">
                  <Button
                    className="competition-button"
                    onClick={() => {
                      history.push(
                        `/competitions/${item.competition_name
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .replace(/[^a-zA-Z0-9 \-]/g, "")
                          .replaceAll(" ", "-")}-${item.id_competition}`
                      );
                    }}
                    theme={props.config_options.id_theme}
                    color={props.config_options.buttons_text_color}
                    backgroundColor={props.config_options.buttons_color}
                  >
                    Vezi rezultate{" "}
                    <ArrowRight
                      fill={props.config_options.buttons_text_color}
                      className="discover-more-button-icon"
                    />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
