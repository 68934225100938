import React from "react";
import "./Textarea.scss";

const Textarea = ({ handleChange, label, ...otherInputProps }) => (
  <div
    className={
      otherInputProps.halfWidth
        ? "text-area-group half-width"
        : "text-area-group full-width"
    }
  >
    {label ? (
      <label
        className={`${
          otherInputProps.value.length ? "shrink" : ""
        } form-textarea-label`}
      >
        {label}
      </label>
    ) : null}
    <textarea
      className="text-area"
      onChange={handleChange}
      {...otherInputProps}
    />
  </div>
);

export default Textarea;
