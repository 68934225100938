import React, { useState } from "react";

export default function CompetitionResults(props) {
  const [rounds, setRounds] = useState([]);
  return (
    <div className="competition-results-container">
      {props.results.map((item) => {
        return (
          <div className="round-container">
            <div className="round-name" style={{ color: props.config_options.section_title_color }}>Etapa {item.round_number}</div>
            <div className="round-date" style={{ color: props.config_options.article_paragraph_color }}>
              <svg className="round-date-img" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="18px" height="21px" version={1.0} style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }} viewBox="0 0 13687 15683" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                  <style type="text/css" dangerouslySetInnerHTML={{ __html: `.fil0 {fill:${props.config_options.main_color}} .fil1 {fill:${props.config_options.clock_lines_icon_color};fill-rule:nonzero}` }} />
                </defs>
                <g id="Layer_x0020_1">
                  <metadata id="CorelCorpID_0Corel-Layer" />
                  <rect className="fil0" y={1996} width={13687} height={13687} />
                  <g>
                    <path className="fil1" d="M9884 0l0 3993 -1521 0 0 -3993 1521 0z" />
                  </g>
                  <g>
                    <path className="fil1" d="M5323 0l0 3993 -1521 0 0 -3993 1521 0z" />
                  </g>
                </g>
              </svg> {item.round_start_date}
            </div>
            <div className="matches-container" style={{ color: props.config_options.section_title_color }}>
              {item.round_matches.map((match) => {
                return (
                  <div className="match-row" style={{ borderRadius: props.config_options.id_theme == 2 ? 15 : 0 }}>
                    <div className="match-team-home">
                      <div className="team-name">{match.team_home_name}</div>

                    </div>
                    <div className="match-score">
                      <div className="number">{match.team_home_goals}</div>
                      <div className="points"> : </div>
                      <div className="number">{match.team_away_goals}</div>
                    </div>
                    <div className="match-team-away">

                      <div className="team-name">{match.team_away_name}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
