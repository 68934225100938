import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/svg/close.svg";
import "./Gallery.scss";
import { baseUrl } from "../../../utils/functions";

export default function ExpandedImage(props) {
  return (
    <div className="dark-background-popup">
      {/* <div className="expanded-image-buttons">
        <div className="button-expanded-image">
          <img src={arrow_left} alt="prev" />
        </div>
        <div className="button-expanded-image">
          <img src={arrow_right} alt="next" />
        </div>
      </div> */}

      <div className="image-container">
        {/* <div className="expanded-image-buttons-mobile">
          <div className="button-expanded-image">
            <img src={arrow_left} alt="prev" />
          </div>
          <div className="button-expanded-image">
            <img src={arrow_right} alt="next" />
          </div>
        </div> */}
        <img
          className="expanded-image"
          src={baseUrl(`uploads/site/photos/${props.id_club}/${props.image}`)}
          alt="expanded_image"
        />
        <div className="close-button" onClick={() => props.closeExpand()} style={{ borderRadius: props.config_options.id_theme == 2 ? '50%' : 0, backgroundColor: props.config_options.buttons_color }}>
          <CloseIcon fill={props.config_options.buttons_text_color} className="close-button-img" />
        </div>
      </div>
    </div>
  );
}
