import React from "react";
import "./NewsIndividual.scss";
import News from "../../sections/News/News.jsx";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";

class NewsIndividual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      date: "",
      image: "",
      content: [],
      loaded: false,
      has_access: true,
    };
  }

  componentDidMount = () => {
    this.hasAccessToNews();
  };

  hasAccessToNews = () => {
    const pathNewsName = this.props.match.params.id.split("-");
    const newsId = pathNewsName.slice(-1)[0];
    axios
      .post("site/has_access_to_news", {
        id_club: this.props.id_club,
        id_site_news: newsId,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data == 0) {
          this.setState({
            has_access: false,
          });
        } else {
          this.getNewsData();
        }
      });
  };

  getNewsData = () => {
    const pathNewsName = this.props.match.params.id.split("-");
    const newsId = pathNewsName.slice(-1);
    axios
      .get(`site/get_news_content?id_news=${newsId}`)
      .then((res) => {
        this.setState({
          title: res.data.title,
          date: res.data.date,
          image: res.data.image,
          content: res.data.content,
          loaded: true,
        });
      })
      .catch((err) => console.log(err));
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ loaded: false }, this.getNewsData);
    }
  };

  checkUrl = (val) => {
    var url = val.match(/\bhttps?:\/\/\S+/gi);
    if (url != null) {
      window.open(url, "_blank");
    }
  };

  render() {
    if (!this.state.has_access) {
      return <Redirect to="/news" />;
    }

    return (
      <>
        {!this.state.loaded ? (
          <div className="loader-container">
            <Loader
              type="ThreeDots"
              color={this.props.config_options.main_color}
              height={80}
              width={80}
            />
          </div>
        ) : (
          <div className="news-individual-page">
            <div className="article-container">
              <div className="article-photo">
                <img
                  src={this.state.image}
                  alt="article_image"
                  style={{
                    borderRadius:
                      this.props.config_options.id_theme == 2 ? 15 : 0,
                  }}
                />
              </div>
              <div className="article-content-container">
                <div
                  className="article-title"
                  style={{
                    color: this.props.config_options.section_title_color,
                  }}
                >
                  {this.state.title}
                </div>
                <div
                  className="article-date"
                  style={{
                    color: this.props.config_options.article_paragraph_color,
                  }}
                >
                  <div className="clock-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlSpace="preserve"
                      version="1.1"
                      style={{
                        shapeRendering: "geometricPrecision",
                        textRendering: "geometricPrecision",
                        imageRendering: "optimizeQuality",
                        fillRule: "evenodd",
                        clipRule: "evenodd",
                      }}
                      viewBox="0 0 13966 13966"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className="clock-icon-icon"
                    >
                      <defs>
                        <style
                          type="text/css"
                          dangerouslySetInnerHTML={{
                            __html: `.str0 {stroke:${this.props.config_options.clock_lines_icon_color};stroke-width:1552.37} .fil1 {fill:none} .fil0 {fill:${this.props.config_options.main_color}}`,
                          }}
                        />
                      </defs>
                      <g id="Layer_x0020_1">
                        <metadata id="CorelCorpID_0Corel-Layer" />
                        <circle className="fil0" cx={6983} cy={6983} r={6983} />
                        <polyline
                          className="fil1 str0"
                          points="6983,4072 6983,6983 11057,6983 "
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="date">{this.state.date}</div>
                </div>
                <div className="article-content">
                  {this.state.content.map((item) => {
                    return (
                      <>
                        {item.type === "Paragraf" && (
                          <div
                            className="article-content-paragraph"
                            style={{
                              color:
                                this.props.config_options
                                  .article_paragraph_color,
                              cursor:
                                item.value.match(/\bhttps?:\/\/\S+/gi) != null
                                  ? "pointer"
                                  : "",
                            }}
                            onClick={() => this.checkUrl(item.value)}
                          >
                            {item.value}
                          </div>
                        )}
                        {item.type === "Subtitlu" && (
                          <div
                            className="article-content-subtitle"
                            style={{
                              color:
                                this.props.config_options
                                  .article_subtitle_color,
                            }}
                          >
                            {item.value}
                          </div>
                        )}
                        {item.type === "Imagine" && (
                          <div className="article-content-image">
                            <img
                              src={item.value}
                              alt="img"
                              style={{
                                borderRadius:
                                  this.props.config_options.id_theme == 2
                                    ? 15
                                    : 0,
                              }}
                            />
                          </div>
                        )}
                        {item.type === "Text evidentiat" && (
                          <div
                            className="article-content-highlighted-text"
                            style={{
                              color:
                                this.props.config_options
                                  .article_subtitle_color,
                              borderLeft: `solid 1px ${this.props.config_options.main_color}`,
                              cursor:
                                item.value.match(/\bhttps?:\/\/\S+/gi) != null
                                  ? "pointer"
                                  : "",
                            }}
                            onClick={() => this.checkUrl(item.value)}
                          >
                            {item.value}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="other-news-container">
              <div className="other-news-container">
                <News
                  title="Alte știri"
                  except_id={this.props.match.params.id}
                  id_club={this.props.id_club}
                  config_options={this.props.config_options}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default NewsIndividual;
