import React, { useEffect, useState } from "react";
import "./Groups.scss";
import GroupElement from "./GroupIndividual";

import axios from "axios";
import Loader from "react-loader-spinner";

export default function Groups(props) {
  const [groups, setGroups] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(`site/get_teams?id_club=${props.id_club}`)
      .then((res) => {
        setGroups(res.data);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="groups-page-container">
      <div
        className="groups-page-title"
        style={{ color: props.config_options.section_title_color }}
      >
        {Number(props.id_club) === 43 ? "Echipă" : "Grupe"}
      </div>
      {!loaded ? (
        <div className="loader-container">
          <Loader
            type="ThreeDots"
            color={props.config_options.main_color}
            height={80}
            width={80}
          />
        </div>
      ) : (
        <div className="groups-list">
          {groups.map((item, index) => {
            return (
              <GroupElement
                team={item}
                config_options={props.config_options}
                key={"group-page" + index}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}