import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-alice-carousel/lib/alice-carousel.css";
import axios from "axios";

let base_url = "https://api.thefootballbrain.app";

const { NODE_ENV } = process.env;
if (NODE_ENV == "development") {
  base_url = "http://localhost/api-tfb/";
}

axios.defaults.baseURL = base_url;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
