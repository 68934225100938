import React from "react";
import "./Loader.scss";
import logo_simbol from "./../../../assets/img/logo-dark.png";

export default function Loader(){
    return (
      <div id="layout_loader_site_fixed_id" className="layout-loader-container">
        <div className="flex_div">
          <img src={logo_simbol} />
        </div>
      </div>
    );
}