import React, { useEffect, useRef } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/svg/close.svg";
import "./Expanded.scss";

function useOutsideAlerter(ref, props) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        props.closeExpand();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function Expanded(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props);
  return (
    <div className="dark-background-popup-homepage">
      {/* <div className="expanded-image-buttons">
        <div className="button-expanded-image">
          <img src={arrow_left} alt="prev" />
        </div>
        <div className="button-expanded-image">
          <img src={arrow_right} alt="next" />
        </div>
      </div> */}

      <div className="image-container">
        {/* <div className="expanded-image-buttons-mobile">
          <div className="button-expanded-image">
            <img src={arrow_left} alt="prev" />
          </div>
          <div className="button-expanded-image">
            <img src={arrow_right} alt="next" />
          </div>
        </div> */}
        <img
          className="expanded-image"
          src={props.image}
          alt="expanded_image"
          ref={wrapperRef}
          style={{ borderRadius: props.config_options.id_theme == 2 ? 15 : 0 }}
        />
        <div className="close-button" onClick={() => props.closeExpand()} style={{
          borderRadius: props.config_options.id_theme == 2 ? '50% ' : 0, backgroundColor: props.config_options.buttons_color
        }}>
          <CloseIcon fill={props.config_options.buttons_text_color} className="close-button-img" />
        </div>
      </div>
    </div>
  );
}
