import React, { useEffect, useState } from "react";
import "../OnlinePayments/OnlinePayments.scss";
import "./RegistrationForm.scss";
import axios from "axios";
import "../Competitions/Competitions.scss";
import Input from "../../reusable/Input/Input";
import Button from "../../reusable/Button/Button";
import Textarea from "../../reusable/Textarea/Textarea";
import Select from "react-select";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import close from "../../../assets/icons/close.png";

import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@mui/styles";
import { ReactComponent as ArrowRight } from "../../../assets/icons/svg/arrow-right.svg";

export default function RegistrationForm(props) {
  const [childFirstname, setChildFirstname] = useState("");
  const [childLastname, setChildLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [parentFirstname, setParentFirstname] = useState("");
  const [parentLastname, setParentLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [adress, setAdress] = useState("");
  const [optionalMessage, setOptionalMessage] = useState("");
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [selectedRelationship, setSelectedRelationship] = useState("");
  const [validationDate, setValidationDate] = useState(null);

  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);

  //Invalid data
  const [wrongPhoneNumber, setWrongPhoneNumber] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);

  const [stylesFocused, setStylesFocused] = useState([
    {
      borderBottom: `1px solid grey`,
    },
    {
      borderBottom: `1px solid grey`,
    },
    {
      borderBottom: `1px solid grey`,
    },
    {
      borderBottom: `1px solid grey`,
    },
    {
      borderBottom: `1px solid grey`,
    },
    {
      borderBottom: `1px solid grey`,
    },
    {
      borderBottom: `1px solid grey`,
    },
    {
      borderBottom: `1px solid grey`,
    },
  ]);

  const classes = makeStyles({
    root: {
      // input label when focused
      "& label.Mui-focused": {
        color: "black !important",
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottom: `1px solid ${props.config_options.buttons_color}`,
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottom: `1px solid ${props.config_options.buttons_color}`,
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderBottom: `1px solid ${props.config_options.buttons_color}`,
        },
      },
    },
  });

  useEffect(() => {
    getRelaionships();
  }, []);

  const getRelaionships = () => {
    axios
      .get(`parentsApp/get_relationships?id_club=${props.id_club}`)
      .then((res) => {
        setRelationshipOptions(res.data);
      })
      .catch((err) => console.log(err));
  };

  function validateData(fieldName, value) {
    let isWrongNumber = wrongPhoneNumber;
    let isWrongEmail = wrongEmail;

    if (value) {
      switch (fieldName) {
        case "email":
          isWrongEmail = !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
          break;
        case "phoneNumber":
          if (value.indexOf(7) == 3) {
            isWrongNumber = !isValidPhoneNumber(value);
          } else {
            isWrongNumber = true;
          }
          break;
        default:
          break;
      }
      setWrongEmail(isWrongEmail);
      setWrongPhoneNumber(isWrongNumber);
    }
  }

  const handleInputFocus = (index) => {
    let styles = [...stylesFocused];
    styles[index] = {
      borderBottom: `1px solid ${props.config_options.buttons_color}`,
    };
    setStylesFocused(styles);
  };

  const handleInputBlur = (index) => {
    let styles = [...stylesFocused];
    styles[index] = { borderBottom: `1px solid grey` };
    setStylesFocused(styles);
  };

  const isDisabled = () => {
    return (
      childFirstname == "" ||
      childLastname == "" ||
      parentFirstname == "" ||
      parentLastname == "" ||
      dateOfBirth == null ||
      validationDate == "invalidDate" ||
      selectedRelationship == "" ||
      email == "" ||
      phone == "" ||
      city == "" ||
      adress == "" ||
      (wrongEmail && email.length > 0) ||
      (wrongPhoneNumber && phone.length > 0)
    );
  };

  const resetFields = () => {
    setChildFirstname("");
    setChildLastname("");
    setDateOfBirth(null);
    setParentFirstname("");
    setParentLastname("");
    setEmail("");
    setPhone("");
    setCity("");
    setAdress("");
    setOptionalMessage("");
    setSelectedRelationship("");
    setConfirmationPopup(false);
  };

  function sendForm() {
    const payload = {
      child_first_name: childFirstname,
      child_last_name: childLastname,
      date_of_birth: moment(dateOfBirth).format("YYYY-MM-DD"),
      parent_first_name: parentFirstname,
      parent_last_name: parentLastname,
      relationship: selectedRelationship,
      email: email,
      phone: phone,
      city: city,
      adress: adress,
      optional_message: optionalMessage,
      id_club: props.id_club,
      club_name: props.club_name,
    };

    axios
      .post("site/send_enrolment_request",
        payload
      )
      .then((res) => {
        if (res.data.success == 1) {
          setConfirmationPopup(true);
          setSuccessPopup(true);
        } else {
          setConfirmationPopup(true);
          setSuccessPopup(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setConfirmationPopup(true);
        setSuccessPopup(false);
      });
  }

  return (
    <div
      className={`onlinepayments-page-container${props.onlyIframe == 1 ? " no-margin-top no-padding-bottom" : ""
        }`}
      style={{ color: props.config_options.contact_color }}
    >
      <div className="onlinepayments-title">{"FORMULAR DE ÎNSCRIERE"}</div>

      <div className="child-registration-form">
        <div className="child-registration-form-input fullname-row">
          <div className="first-name">
            <Input
              name="child_first_name"
              label="Prenume Copil"
              aditionalClasses={childFirstname != "" && "on-complete-field"}
              value={childFirstname}
              handleChange={(e) => {
                setChildFirstname(e.target.value);
              }}
              style={stylesFocused[0]}
              onFocus={() => handleInputFocus(0)}
              onBlur={() => handleInputBlur(0)}
            />
          </div>
          <div className="last-name">
            <Input
              name="lastname"
              label="Nume de familie copil"
              value={childLastname}
              aditionalClasses={childLastname != "" && "on-complete-field"}
              handleChange={(e) => {
                setChildLastname(e.target.value);
              }}
              style={stylesFocused[1]}
              onFocus={() => handleInputFocus(1)}
              onBlur={() => handleInputBlur(1)}
            />
          </div>
        </div>
        <div className="child-registration-form-input">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              // className={`child-dob ${classes().root}`}
              className={`child-dob`}
              label="Data nașterii copil"
              value={dateOfBirth}
              onChange={(newDob, e) => {
                if (newDob) setDateOfBirth(newDob.$d);
                setValidationDate(e.validationError);
              }}
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
            />
          </LocalizationProvider>
        </div>{" "}
        <div className="child-registration-form-input fullname-row">
          <div className="first-name">
            <Input
              name="parent_firstname"
              label="Prenume adult"
              value={parentFirstname}
              aditionalClasses={parentFirstname != "" && "on-complete-field"}
              handleChange={(e) => {
                setParentFirstname(e.target.value);
              }}
              style={stylesFocused[2]}
              onFocus={() => handleInputFocus(2)}
              onBlur={() => handleInputBlur(2)}
            />
          </div>

          <div className="last-name">
            <Input
              name="lastname"
              label="Nume de familie adult"
              value={parentLastname}
              aditionalClasses={parentLastname != "" && "on-complete-field"}
              handleChange={(e) => {
                setParentLastname(e.target.value);
              }}
              style={stylesFocused[3]}
              onFocus={() => handleInputFocus(3)}
              onBlur={() => handleInputBlur(3)}
            />
          </div>
        </div>
        <div className="child-registration-form-input">
          <label className="child-input-label" style={{ paddingLeft: "5px" }}>
            {"Relația cu jucătorul"}
          </label>
          <Select
            className="input-select"
            options={relationshipOptions}
            onChange={(selected) => setSelectedRelationship(selected)}
            placeholder={"Selectează..."}
            value={selectedRelationship}
            styles={{
              placeholder: (provided, state) => ({
                ...provided,
                // fontSize: "12px",
              }),
            }}
          />
        </div>
        <div className="child-registration-form-input">
          <Input
            name="email"
            label="Email"
            value={email}
            aditionalClasses={email != "" && "on-complete-field"}
            handleChange={(e) => {
              setEmail(e.target.value);
              validateData("email", e.target.value);
            }}
            style={stylesFocused[4]}
            onFocus={() => handleInputFocus(4)}
            onBlur={() => handleInputBlur(4)}
          />
        </div>{" "}
        <div className="child-registration-form-input">
          <label className="phone-label">{"Număr de telefon"}</label>
          <PhoneInput
            initialValueFormat="national"
            defaultCountry="RO"
            countries={["RO"]}
            placeholder={"Ex: 0712 345 678"}
            value={phone}
            name="phone"
            onChange={(e) => {
              setPhone(e ?? "");
              validateData("phoneNumber", e);
            }}
            style={stylesFocused[5]}
            onFocus={() => handleInputFocus(5)}
            onBlur={() => handleInputBlur(5)}
          />
        </div>
        <div className="child-registration-form-input">
          <Input
            name="city"
            label="Localitate"
            value={city}
            aditionalClasses={city != "" && "on-complete-field"}
            handleChange={(e) => {
              setCity(e.target.value);
            }}
            style={stylesFocused[6]}
            onFocus={() => handleInputFocus(6)}
            onBlur={() => handleInputBlur(6)}
          />
        </div>
        <div className="child-registration-form-input">
          <Input
            name="adress"
            label="Adresă"
            value={adress}
            aditionalClasses={adress != "" && "on-complete-field"}
            handleChange={(e) => {
              setAdress(e.target.value);
            }}
            style={stylesFocused[7]}
            onFocus={() => handleInputFocus(7)}
            onBlur={() => handleInputBlur(7)}
          />
        </div>
        <div className="child-registration-form-input">
          <Textarea
            name="description"
            label="Mesaj"
            className="message"
            placeholder={"Trimiteți un mesaj opțional"}
            value={optionalMessage}
            handleChange={(e) => {
              setOptionalMessage(e.target.value);
            }}
          />
        </div>
        {wrongEmail && email && email.length > 0 && (
          <div className="wrong-email-text">{"Emailul este incorect"}</div>
        )}
        {wrongPhoneNumber && phone && phone.length > 0 && (
          <div className="wrong-phone-number-text">
            {"Numărul de telefon este incorect"}
          </div>
        )}
        <div className="form-send-button">
          <Button
            onClick={() => {
              sendForm();
            }}
            theme={props.config_options.id_theme}
            color={props.config_options.buttons_text_color}
            backgroundColor={props.config_options.buttons_color}
            disabled={isDisabled()}
          >
            {"TRIMITE"}
            <ArrowRight
              fill={props.config_options.buttons_text_color}
              className="send-icon"
            />
          </Button>
        </div>
      </div>

      <Dialog open={confirmationPopup} onClose={() => resetFields()}>
        <DialogContent>
          <div className="confirm-popup-container">
            <div className="confirm-popup-header">
              <div className="confirm-popup-title">Formular de înscriere</div>
              <img
                src={close}
                alt="close"
                style={{ width: 20, height: 20, cursor: "pointer" }}
                onClick={() => {
                  setConfirmationPopup(false);
                  resetFields();
                }}
              />
            </div>
            <div className="confirm-popup-row" style={{ marginBottom: 15 }}>
              {successPopup
                ? "Cererea a fost trimisă cu succes!"
                : "A apărut o eroare! Cererea nu a fost trimisă! Vă rugam reintroduceți datele"}
            </div>
            {successPopup && (
              <div className="confirm-popup-row">
                Vei primi un răspuns din partea echipei {props.club_name} în cel
                mai scurt timp.
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
