import React from "react";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRight } from "../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../utils/functions";

export default function StaffMemberSlider(props) {
  const history = useHistory();
  function addDefaultSrc(event) {
    event.target.src = baseUrl(`uploads/staff_members/placeholder_antrenor.png`);
  }

  return (
    <div
      className="staff-member-slider-card"
      style={{ borderRadius: props.config_options.id_theme == 2 ? 15 : 0 }}
    >
      <div className="staff-member-image-container">
        <img
          src={baseUrl(`uploads/staff_members/${props.item.id_staff_member}`)}
          onError={addDefaultSrc}
          alt="staff_image"
        />
        <div
          className="staff-member-label"
          style={{
            borderRadius:
              props.config_options.id_theme == 2 ? "0px 15px 0px 0px" : 0,
            color: props.config_options.article_paragraph_color,
          }}
        >
          {props.item.staff_member_role}
        </div>
      </div>
      <div
        className="staff-member-name"
        style={{ color: props.config_options.article_subtitle_color }}
      >
        {props.item.staff_member_name}
      </div>
      <div className="see-more-button-staff">
        <Button
          className="arrow-button"
          onClick={() =>
            history.push(
              `/staff/${props.item.staff_member_name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-zA-Z0-9 \-]/g, "")
                .replaceAll(" ", "-")}-${props.item.id_staff_member}`
            )
          }
          theme={props.config_options.id_theme}
          color={props.config_options.buttons_text_color}
          backgroundColor={props.config_options.buttons_color}
        >
          Vezi profil{" "}
          <ArrowRight
            fill={props.config_options.buttons_text_color}
            className="discover-more-button-icon"
          />
        </Button>
      </div>
    </div>
  );
}
