import React from "react";
import "./Groups.scss";
import GroupElement from "./GroupElement";
import { ReactComponent as LeftArrow } from "../../../assets/icons/svg/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/svg/arrow-right.svg";
import AliceCarousel from "react-alice-carousel";
import axios from "axios";

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      activeIndex: 0,
      width: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  onSlideChanged = (item) => {
    this.setState({ activeIndex: item });
  };

  componentDidMount = () => {
    this.getTeams();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  getTeams = () => {
    axios
      .get(`site/get_teams?id_club=${this.props.id_club}`)
      .then((res) => {
        this.setState({ groups: res.data });
      })
      .catch((err) => console.log(err));
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const responsive = {
      // 0: { items: 1 },
      // 850: { items: 2 },
      // 1500: { items: 3 }, 
      0: { items: 1 },
      740: { items: 2 },
      1440: { items: 3 },
      1850: { items: 4 }
    };
    return (
      <div className="groups-section">
        <div
          className="groups-section-title"
          style={{ color: this.props.config_options.section_title_color }}
        >
          Grupe
        </div>
        {this.state.width > 1024 && this.state.groups.length < 4 ? (
          <div className="groups-homepage-carousel-container-not-enough-el">
            {this.state.groups.map((item, index) => {
              return (
                <GroupElement
                  key={"group-element" + index}
                  team={item}
                  config_options={this.props.config_options}
                />
              );
            })}
          </div>
        ) : (
          <div className="groups-homepage-carousel-container">
            <AliceCarousel
              mouseTracking
              disableDotsControls
              disableButtonsControls
              responsive={responsive}
              ref={(el) => (this.Carousel = el)}
            >
              {this.state.groups.map((item, index) => {
                return (
                  <GroupElement
                    key={"group-element" + index}
                    team={item}
                    config_options={this.props.config_options}
                  />
                );
              })}
            </AliceCarousel>
            <div className="groups-homepage-carousel-buttons">
              <div
                className="prev-button-desktop-teams"
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2
                      ? "0px 40px 40px 0px"
                      : "",
                  backgroundColor: this.props.config_options.side_buttons_color,
                }}
                onClick={() => this.Carousel.slidePrev()}
              >
                <LeftArrow
                  className="prev-button-img"
                  fill={this.props.config_options.play_icon_color}
                />
              </div>
              <div
                onClick={() => this.Carousel.slideNext()}
                className="next-button-desktop-teams"
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2
                      ? "40px 0px 0px 40px"
                      : "",
                  backgroundColor: this.props.config_options.side_buttons_color,
                }}
              >
                <RightArrow
                  className="next-button-img"
                  fill={this.props.config_options.play_icon_color}
                />
              </div>
            </div>
            <div className="groups-homepage-carousel-buttons-mobile">
              <div
                className="prev-button-desktop-teams"
                onClick={() => this.Carousel.slidePrev()}
                style={{
                  backgroundColor: this.props.config_options.side_buttons_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <LeftArrow
                  className="prev-button-img"
                  fill={this.props.config_options.play_icon_color}
                />
              </div>
              <div
                onClick={() => this.Carousel.slideNext()}
                className="next-button-desktop-teams"
                style={{
                  backgroundColor: this.props.config_options.side_buttons_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <RightArrow
                  className="next-button-img"
                  fill={this.props.config_options.play_icon_color}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Groups;
