import React from "react";
import "./Gallery.scss";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/svg/expand.svg";
import Button from "../../reusable/Button/Button";
import ExpandedImage from "./ExpandedImage";
import axios from "axios";
import Loader from "react-loader-spinner";
import { baseUrl } from "../../../utils/functions";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      expandedImageSrc: "",
      total_displayed: 12,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.getImages();
  };

  getImages = () => {
    this.setState({ loading: true });
    axios
      .get(
        `site/get_images?id_club=${this.props.id_club}&limit=${this.state.total_displayed}`
      )
      .then((res) => this.setState({ images: res.data, loading: false }))
      .catch((res) => console.log(res));
  };

  raiseLimit = () => {
    this.setState(
      { total_displayed: this.state.total_displayed + 12 },
      this.getImages
    );
  };

  render() {
    return (
      <div className="gallery-section-container">
        <div
          className="gallery-title"
          style={{ color: this.props.config_options.section_title_color }}
        >
          Galerie foto
        </div>
        <div className="photos-grid">
          {this.state.images.map((item, index) => {
            return (
              <div className="element-photo-grid" key={"gallery-" + index}>
                <img
                  src={baseUrl(`uploads/site/photos/${this.props.id_club}/${item.image_name}`)}
                  alt=""
                  style={{
                    borderRadius:
                      this.props.config_options.id_theme == 2 ? 15 : 0,
                  }}
                />
                <div
                  className="expand-button"
                  onClick={() =>
                    this.setState({
                      expandedImageSrc: item.image_name,
                    })
                  }
                  style={{
                    borderRadius:
                      this.props.config_options.id_theme == 2 ? "50%" : "",
                    backgroundColor: this.props.config_options
                      .expand_photo_button_color,
                  }}
                >
                  <ExpandIcon
                    className="expand-photo-button-img"
                    fill={
                      this.props.config_options.expand_photo_button_text_color
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="loader-container" style={{ marginBottom: 25 }}>
          {this.state.loading && (
            <Loader
              type="ThreeDots"
              color={this.props.config_options.main_color}
              height={80}
              width={80}
            />
          )}
        </div>
        <div className="gallery-load-more-button-container">
          <Button
            onClick={this.raiseLimit}
            style={{ minWidth: 300 }}
            theme={this.props.config_options.id_theme}
            color={this.props.config_options.buttons_text_color}
            backgroundColor={this.props.config_options.buttons_color}
          >
            Incarca mai multe imagini
          </Button>
        </div>
        {this.state.expandedImageSrc !== "" && (
          <ExpandedImage
            closeExpand={() => this.setState({ expandedImageSrc: "" })}
            image={this.state.expandedImageSrc}
            id_club={this.props.id_club}
            config_options={this.props.config_options}
          />
        )}
      </div>
    );
  }
}

export default Gallery;
