import React, { useState, useEffect } from "react";
import "../About/About.scss";
import axios from "axios";
import Loader from "react-loader-spinner";

export default function Palmares(props) {
    const [image, setImage] = useState("");
    const [content, setContent] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axios
            .get(`site/palmares?id_club=${props.id_club}`)
            .then((res) => {
                setImage(res.data.image);
                setContent(res.data.content);
                setLoaded(true);
            })
            .catch((err) => console.log(err));
    }, [props.id_club]);

    return (
        <>
            {!loaded ? (
                <div className="loader-container">
                    <Loader
                        type="ThreeDots"
                        color={props.config_options.main_color}
                        height={80}
                        width={80}
                    />
                </div>
            ) : (
                <div className="about-container">
                    <div className="about-photo">
                        <img
                            src={image}
                            alt="article_image"
                            style={{
                                borderRadius: props.config_options.id_theme == 2 ? 15 : 0,
                            }}
                        />
                    </div>
                    <div
                        className="about-title"
                        style={{ color: props.config_options.section_title_color }}
                    >
                        Despre noi
                    </div>
                    <div className="about-text-container">
                        {content.map((item, index) => {
                            return (
                                <div key={"par-" + index}>
                                    {item.type === "Paragraf" && (
                                        <div
                                            className="about-paragraph"
                                            style={{
                                                color: props.config_options.article_paragraph_color,
                                            }}
                                        >
                                            {item.value}
                                        </div>
                                    )}
                                    {item.type === "Subtitlu" && (
                                        <div
                                            className="about-subtitle"
                                            style={{
                                                color: props.config_options.article_subtitle_color,
                                            }}
                                        >
                                            {item.value}
                                        </div>
                                    )}
                                    {item.type === "Imagine" && (
                                        <div className="about-text-photo">
                                            <img
                                                src={item.value}
                                                alt="article_image"
                                                style={{
                                                    borderRadius:
                                                        props.config_options.id_theme == 2 ? 15 : 0,
                                                }}
                                            />
                                        </div>
                                    )}
                                    {item.type === "Text evidentiat" && (
                                        <div
                                            className="about-highlighted-text"
                                            style={{
                                                color: props.config_options.article_subtitle_color,
                                                borderLeft: `solid 1px ${props.config_options.main_color}`,
                                            }}
                                        >
                                            {item.value}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
}