export const baseUrl = (relativePath = "") => {
    let baseurl = "https://api.thefootballbrain.app/";
    const { NODE_ENV } = process.env;

    if (NODE_ENV == "development") {
        baseurl = "http://localhost/api-tfb/";
    }
    return baseurl + relativePath;
}

export const calculateFee = (amount, clubFeePercent, stripeFeePercent, stripeFeeUnits) => {
    amount = Number(amount);
    if (clubFeePercent) {
        clubFeePercent = Number(clubFeePercent) / 100;
        return (amount * clubFeePercent).toFixed(2);
    } else {
        stripeFeePercent = stripeFeePercent / 100;
        return ((amount + stripeFeeUnits) / (stripeFeeUnits - stripeFeePercent) * stripeFeePercent + stripeFeeUnits).toFixed(2);
    }
}