import React, { useState, useEffect } from "react";
import CompetitionRanking from "./CompetitionRanking";
import CompetitionResults from "./CompetitionResults";
import "./Competitions.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/css";
import { Redirect } from "react-router-dom";

export default function CompetitionPage(props) {
  const [competitionMenuOption, setOption] = useState("results");
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [hasAccess, setHasAccess] = useState(true);
  const { id } = useParams();

  const menuOptions = [
    { value: "results", text: "Rezultate" },
    { value: "ranking", text: "Clasament" },
  ];

  useEffect(() => {
    hasAccessToCompetition();
    // getCompetion();
  }, []);

  const getCompetion = () => {
    axios
      .get(`site/get_competition_results?id_comp=${id.split("-").slice(-1)[0]}`)
      .then((res) => {
        setName(res.data.name);
        setResults(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  const hasAccessToCompetition = () => {
    const pathCompetionName = id.split("-");
    const competitionId = pathCompetionName.slice(-1)[0];
    axios
      .post("site/has_access_to_competition", {
        id_club: props.id_club,
        id_competition: competitionId,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data == 0) {
          setHasAccess(false);
        } else {
          getCompetion();
        }
      });
  };

  // if (!hasAccess) {
  //   return <Redirect to="/competitions" />;
  // }

  return (
    <>
      {hasAccess ? (
        <div className="competition-page-container">
          <div
            className="competition-name"
            style={{ color: props.config_options.section_title_color }}
          >
            {name}
          </div>
          <div className="comp-option-picker">
            {menuOptions.map((item) => (
              <div
                className={css`
                  width: 165px;
                  display: flex;
                  border-bottom: solid 1px;
                  align-items: center;
                  justify-content: center;
                  font-size: 16px;
                  cursor: pointer;
                  background-color: ${competitionMenuOption === item.value
                    ? props.config_options.header_option_color
                    : ""};
                  color: ${competitionMenuOption === item.value
                    ? props.config_options.header_color
                    : props.config_options.header_option_color};
                  border-radius: ${competitionMenuOption === item.value &&
                    props.config_options.id_theme == 2
                    ? "12px 12px 0px 0px"
                    : "0px"};
                `}
                onClick={() => setOption(item.value)}
              >
                <div>{item.text}</div>
              </div>
            ))}
          </div>
          <div className="comp-option-picker-mobile">
            <select
              className="menu-mobile-dropdown"
              id="comp-view-options"
              onChange={(e) => setOption(e.target.value)}
              style={{
                backgroundColor: props.config_options.side_buttons_color,
                color: props.config_options.expand_photo_button_color,
                borderRadius: props.config_options.id_theme == 2 ? 15 : 0,
              }}
            >
              <option value="results">Rezultate</option>
              <option value="ranking">Clasament</option>
            </select>
          </div>
          {competitionMenuOption === "results" ? (
            <CompetitionResults
              results={results}
              config_options={props.config_options}
            />
          ) : (
            <CompetitionRanking
              id_competition={id.split("-").slice(-1)[0]}
              id_club={props.id_club}
              config_options={props.config_options}
            />
          )}
        </div>
      ) : (
        <Redirect to="/competitions" />
      )}
    </>
  );
}
