import React from "react";
import "./Input.scss";

const Input = ({ handleChange, label, halfWidth, aditionalClasses = "", ...otherInputProps }) => (
  <div
    className={
      halfWidth ? "group half-width" : "group full-width"
    }
  >
    <input
      className="form-input"
      onChange={handleChange}
      {...otherInputProps}
    />
    {label ? (
      <label
        className={`${otherInputProps.value.length ? "shrink" : ""
          } form-input-label ${aditionalClasses}`}
      >
        {label}
      </label>
    ) : null}
  </div>
);

export default Input;
