import React, { useState, useEffect } from "react";
import "./PrivacyPolicy.scss";
import axios from "axios";
import Loader from "react-loader-spinner";

export default function PrivacyPolicy(props) {
    const [content, setContent] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axios
            .get(`site/privacy_policy_content?id_club=${props.id_club}`)
            .then((res) => {
                setContent(String(res.data.privacy_policy ?? "").replace(/\&nbsp;/g, ' '));
                setLoaded(true);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div className="about-container">
            {!loaded ?
                <div className="loader-container">
                    <Loader type="ThreeDots" color={props.config_options.main_color} height={80} width={80} />
                </div> :
                <>
                    <div className="about-title" style={{ color: props.config_options.section_title_color }}>Politica de confidențialitate</div>
                    <div className="about-text-container">
                        <div className="render-privacy-policy" dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </>
            }
        </div>
    )
}
