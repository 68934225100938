import React from "react";
import HomepageIntro from "../sections/HomepageIntro/HomepageIntro";
import News from "../sections/News/News.jsx";
import Social from "../sections/Social/Social.jsx";
import Groups from "../sections/Groups/Groups";
import Photos from "../sections/Photos/Photos";

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.newsRef = React.createRef();
  }

  scrollToNews = () =>
    this.newsRef.scrollIntoView({ behavior: "smooth", block: "start" });

  render() {
    return (
      <div>
        <HomepageIntro
          scrollToNews={this.scrollToNews}
          id_club={this.props.id_club}
          club_name={this.props.club_name}
          description={this.props.description}
          theme={this.props.theme}
          main_color={this.props.main_color}
          config_options={this.props.config_options}
          website_settings={this.props.website_settings}
        />
        <div ref={(ref) => (this.newsRef = ref)}>
          <News
            title="Știri"
            id_club={this.props.id_club}
            config_options={this.props.config_options}
          />
        </div>
        <Social
          id_club={this.props.id_club}
          theme={this.props.theme}
          main_color={this.props.main_color}
          config_options={this.props.config_options}
        />
        <Photos
          id_club={this.props.id_club}
          title={this.props.theme === 1 ? "Galerie" : "Galerie foto"}
          theme={this.props.theme}
          main_color={this.props.main_color}
          config_options={this.props.config_options}
        />
        <Groups
          id_club={this.props.id_club}
          config_options={this.props.config_options}
          theme={this.props.theme}
        />
      </div>
    );
  }
}

export default Homepage;
